<!--  -->
<template>
  <div class="addAssets">
    <van-form class="main-wrapper" ref="publishNotice">
      <van-field
        required
        v-model="ruleForm.faName"
        name="faName"
        label="名称"
        placeholder="请输入固定资产名称"
        maxlength="20"
      />
      <van-field
        v-model="ruleForm.modelType"
        name="modelType"
        label="型号"
        placeholder="请输入型号"
        maxlength="20"
      />
      <van-field
        v-model.trim="ruleForm.faPrice"
        type="digit"
        required
        name="faPrice"
        label="资产金额(元)"
        maxlength="7"
      />
      <van-field
        readonly
        clickable
        name="departmentCode"
        v-model="departmentName"
        label="选择部门"
        placeholder="选择部门"
        @click="showDepartment = true"
        right-icon="arrow"
      >
      </van-field>
      <van-popup v-model="showDepartment" position="bottom">
        <van-picker
          v-if="departmentList && departmentList.length"
          show-toolbar
          :columns="departmentList"
          @confirm="departmentConfirm"
          @cancel="showDepartment = false"
        />
        <van-empty v-else description="暂无部门" />
      </van-popup>
      <van-field
        v-model="ruleForm.faLocation"
        name="faLocation"
        label="位置"
        placeholder="请输入位置"
        maxlength="120"
      />
      <van-field
        required
        readonly
        clickable
        name="faStatus"
        v-model="faStatusName"
        label="资产状态"
        placeholder="选择状态"
        @click="showFaStatus = true"
        right-icon="arrow"
      >
      </van-field>
      <van-popup v-model="showFaStatus" position="bottom">
        <van-picker
          v-if="faStatusList && faStatusList.length"
          show-toolbar
          :columns="faStatusList"
          @confirm="faStatusConfirm"
          @cancel="showFaStatus = false"
        />
      </van-popup>
      <van-field
        readonly
        required
        clickable
        name="tagStyle"
        v-model="tagStyleName"
        label="标签状态"
        placeholder="选择标签状态"
        @click="showTagStyle = true"
        right-icon="arrow"
      >
      </van-field>
      <van-popup v-model="showTagStyle" position="bottom">
        <van-picker
          v-if="tagStyleList && tagStyleList.length"
          show-toolbar
          :columns="tagStyleList"
          @confirm="tagStyleConfirm"
          @cancel="showTagStyle = false"
        />
      </van-popup>
      <van-field
        required
        v-model="ruleForm.faCode"
        name="faCode"
        label="资产编码"
        placeholder="请输入资产编码"
        maxlength="20"
      />
      <van-field
        readonly
        clickable
        name="principalCode"
        v-model="principalName"
        label="负责人"
        placeholder="选择负责人"
        @click="showPrincipal = true"
        right-icon="arrow"
      >
      </van-field>
      <van-popup v-model="showPrincipal" position="bottom">
        <van-picker
          v-if="staffData && staffData.length"
          show-toolbar
          :columns="staffData"
          @confirm="staffConfirm"
          @cancel="showPrincipal = false"
        />
        <van-empty v-else description="暂无工作人员" />
      </van-popup>
      <van-field
        required
        readonly
        clickable
        v-model="useTime"
        name="useTime"
        label="使用日期"
        placeholder="请选择使用日期"
        maxlength="50"
        @click="showUseTime = true"
        right-icon="arrow"
      />
      <van-popup v-model="showUseTime" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          :min-date="minDate"
          :max-date="maxDate"
          title="选择日期"
          @confirm="useTimeConfirm"
          @cancel="showUseTime = false"
        />
      </van-popup>
      <div class="card" v-if="ruleForm.faStatus == 3">
        <div class="sub-title">报废描述</div>

        <textarea
          v-model="ruleForm.wasteReason"
          name="wasteReason"
          placeholder="请输入报废描述"
          maxlength="120"
          cols="30"
          rows="5"
          class="text-area"
        />
      </div>
      <div class="card" v-if="ruleForm.faStatus == 2">
        <div class="sub-title">故障描述</div>

        <textarea
          v-model="ruleForm.troubleReason"
          name="troubleReason"
          placeholder="请输入故障描述"
          maxlength="120"
          cols="30"
          rows="5"
          class="text-area"
        />
      </div>
      <div class="card">
        <div class="sub-title">备注</div>

        <textarea
          v-model="ruleForm.remarkDesc"
          name="remarkDesc"
          placeholder="请输入备注"
          maxlength="120"
          cols="30"
          rows="5"
          class="text-area"
        />
      </div>
      <div class="upload-box">
        <div class="sub-title">图片（ 非必填，最多三张）</div>
        <van-uploader
          v-model="fileList"
          :max-size="10 * 1024 * 1024"
          :before-read="beforeRead"
          :after-read="afterRead"
          @oversize="onOversize"
          class="upload"
          :max-count="3"
        />
      </div>
    </van-form>
    <div class="bottom">
      <div class="button" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
import Schema from "async-validator";
import {
  saveFixedAssets,
  queryFixedAssetsInfo,
  editFixedAssets,
} from "@/api/fixedAssets/index.js";
import { getAllStaffList } from "@/api/staff/index.js";
import { getAllList } from "@/api/addressList/index.js";
import { uploadImgNoPrefix } from "@/api/cdn";
import { Toast } from "vant";
export default {
  data() {
    const checkDepart = (rule, value, callback) => {
      if (!this.ruleForm.principalCode) {
        return callback();
      } else {
        if (!value) return callback(new Error("请选择部门"));
        return callback();
      }
    };
    const checkFaLocalt = (rule, value, callback) => {
      if (!this.ruleForm.principalCode) {
        return callback();
      } else {
        if (!value) return callback(new Error("请输入位置"));
        return callback();
      }
    };
    const checkModelType = (rule, value, callback) => {
      if (!this.ruleForm.principalCode) {
        return callback();
      } else {
        if (!value) return callback(new Error("请选择资产型号"));
        return callback();
      }
    };
    return {
      ruleForm: {
        departmentCode: "", //所属部门
        faCode: "", //资产编码
        faLocation: "", //位置
        faName: "", //固定资产名称
        faPic: "", //资产图片，最多三张，英文逗号隔开
        faPrice: null, //价格(单位：分)
        faStatus: null, //状态：1-正常 2-故障 3-报废
        modelType: "", //型号
        principalCode: "", //负责人工号
        remarkDesc: "", //备注
        tagCode: "",
        tagStyle: null, //标签状态：1-未贴 2-已贴 3-无法粘贴
        useTime: "", //使用日期
        wasteReason: "",
        troubleReason: "",
      },
      rules: {
        faName: [
          { required: true, message: "请输入资产名称", trigger: "blur" },
        ],
        faPrice: [
          {
            required: true,
            type: "number",
            message: "请输入资产金额",
            trigger: "blur",
          },
          {
            pattern: /^[1-9]{1}[0-9]{0,6}$/,
            type: "number",
            message: "资产金额为正整数",
            trigger: "blur",
          },
        ],
        faStatus: [
          {
            required: true,
            type: "number",
            message: "请选择资产状态",
            trigger: "change",
          },
        ],
        useTime: [
          { required: true, message: "请选择使用日期", trigger: "change" },
        ],
        departmentCode: [{ validator: checkDepart, trigger: "blur" }],
        faLocation: [{ validator: checkFaLocalt, trigger: "blur" }],
        modelType: [{ validator: checkModelType, trigger: "blur" }],
        faCode: [
          { required: true, message: "请输入资产编码", trigger: "blur" },
        ],
      },
      showDepartment: false,
      departmentList: [],
      staffData: [],
      principalName: "",
      showPrincipal: false,
      departmentName: "",
      showFaStatus: false,
      faStatusName: "",
      faStatusList: [
        { text: "正常", value: 1 },
        { text: "故障", value: 2 },
        { text: "报废", value: 3 },
      ],
      tagStyleName: "",
      showTagStyle: false,
      tagStyleList: [
        { text: "未贴", value: 1 },
        { text: "已贴", value: 2 },
        { text: "无法粘贴", value: 3 },
      ],
      showUseTime: false,
      useTime: "",
      currentDate: new Date(),
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2060, 10, 1),
      fileList: [],
      validator: null,
      detail: null,
    };
  },

  components: {},

  computed: {
    id() {
      if (!this.$route.query) return "";
      return this.$route.query.bizCode;
    },
  },

  mounted() {
    this.initValidator();
    this.getResourceList();
    this.getAllStaff();
    if (this.id) {
      this.getAssetsInfo();
    }
  },

  methods: {
    initValidator() {
      this.validator = new Schema(this.rules);
    },
    //格式化数据
    initData() {
      this.ruleForm = this.detail;
      this.ruleForm.faPrice = this.detail.faPrice / 100;
      //图片还未格式化
      if (this.detail.faPicList) {
        this.fileList = this.detail.faPicList.map((e) => {
          return {
            resData: {
              fileCode: e.code,
              fileUrl: e.url,
            },
            ...e,
          };
        });
      }
      if (this.detail.departmentName) {
        this.departmentName = this.detail.departmentName;
      }
      if (this.detail.principalName) {
        this.principalName = this.detail.principalName;
      }
      if (this.detail.useTime) {
        this.useTime = this.detail.useTime;
      }
      if (this.detail.faStatus) {
        let obj = {
          1: "正常",
          2: "故障",
          3: "报废",
        };
        this.faStatusName = obj[this.detail.faStatus];
      }
      if (this.detail.tagStyle) {
        let obj = {
          1: "未贴",
          2: "已贴",
          3: "无法粘贴",
        };
        this.tagStyleName = obj[this.detail.tagStyle];
      }
    },
    //获取详情
    getAssetsInfo() {
      queryFixedAssetsInfo({ bizCode: this.id }).then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.detail = data;
            this.initData();
          }
        }
      });
    },
    //获取部门
    getResourceList() {
      getAllList().then((res) => {
        let { code, data } = res;
        if (code == 200) {
          this.departmentList = [];
          this.departmentList = data.map((e) => {
            return {
              text: e.departmentName,
              value: e.departmentCode,
            };
          });
        }
      });
    },
    //员工
    getAllStaff() {
      getAllStaffList().then((res) => {
        let { data, code } = res;
        if (code == 200) {
          this.staffData = data.map((e) => {
            return {
              text: e.userName,
              value: e.staffCode,
            };
          });
        }
      });
    },
    // 部门
    departmentConfirm(e) {
      this.showDepartment = false;
      this.departmentName = e.text;
      this.ruleForm.departmentCode = e.value;
    },
    // 状态
    faStatusConfirm(e) {
      this.showFaStatus = false;
      this.faStatusName = e.text;
      this.ruleForm.faStatus = e.value;
    },
    // 标签
    tagStyleConfirm(e) {
      this.showTagStyle = false;
      this.tagStyleName = e.text;
      this.ruleForm.tagStyle = e.value;
    },
    //负责人
    staffConfirm(e) {
      this.showPrincipal = false;
      this.principalName = e.text;
      this.ruleForm.principalCode = e.value;
    },
    //使用时间
    useTimeConfirm(e) {
      this.useTime = this.$utils.formatDateTime(e);
      this.ruleForm.useTime = this.$utils.formatDateTime(e);
      this.showUseTime = false;
    },
    // 返回布尔值
    beforeRead(file) {
      if (["image/jpeg", "image/png"].indexOf(file.type) == -1) {
        Toast("请上传 jpg/png 格式图片");
        return false;
      }
      return true;
    },
    onOversize(file) {
      Toast("文件大小不能超过 10M");
    },
    afterRead(file) {
      let params = new FormData();
      params.append("file", file.file);
      Toast.loading({
        message: "文件上传中...",
        forbidClick: true,
        duration: 0,
      });
      uploadImgNoPrefix(params)
        .then((res) => {
          Toast.clear();
          Toast("上传成功");
          file.resData = res.data;
        })
        .catch((e) => {
          Toast.clear();
          Toast("上传失败");
        });
    },
    submit() {
      const imgArr = this.fileList.map((item) => {
        return item.resData.fileCode;
      });
      let postData = {
        ...this.ruleForm,
        faPic: imgArr.toString(),
        faPrice: this.ruleForm.faPrice * 100,
      };
      this.validator
        .validate(postData)
        .then((res) => {
          if (this.id) {
            this.edit(postData);
          } else {
            this.add(postData);
          }
        })
        .catch((e) => {
          Toast(e.errors[0].message);
        });
    },
    //添加
    add(parmas) {
      saveFixedAssets(parmas).then((res) => {
        Toast("添加成功");
        this.$router.back();
      });
    },
    // 编辑
    edit(parmas) {
      editFixedAssets(parmas).then((res) => {
        Toast("成功");
        this.$router.back();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.addAssets {
  min-height: 100%;
  background: #fafafa;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  .sub-title {
    color: #646566;
    font-size: 14px;
    margin-bottom: 0.2rem;
  }
  .card {
    background: #fff;
    padding: 0.2rem 0.3rem;

    .tag-ul {
      margin-top: 0.2rem;
      margin-bottom: 0.3rem;
    }

    .text-area {
      background: #f6fafe;
      border-radius: 0.1rem;
      width: 100%;
      padding: 0.2rem;
    }

    .add-img {
      height: 1rem;
      width: 1rem;
    }
  }
  .upload-box {
    background: #fff;
    padding: 0.2rem;
    margin-bottom: 0.2rem;
  }
  .bottom {
    padding: 0.2rem 0.3rem;
    .button {
      width: 6.9rem;
      height: 0.8rem;
      background: #0094ee;
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.37rem;
    }
  }
}
</style>
